import { render, staticRenderFns } from "./index.vue?vue&type=template&id=53b77969&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=53b77969&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53b77969",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainHeader: require('/opt/kras.aero/src/components/main-header.vue').default,MainFooter: require('/opt/kras.aero/src/components/main-footer.vue').default})
