
import {mapGetters} from 'vuex';
import {rzContainer} from 'razlet-ui';
import MainLogo from '~/components/main-logo';

export default {
  name: 'footer-block',
  components: {
    MainLogo,
    rzContainer,
  },
  data() {
    return {
      year: (new Date()).getFullYear(),
    };
  },
  computed: {
    ...mapGetters(['menu']),
  },
};
